$font-size: 14px;

:root {
  font-size: $font-size;

  --bg-color: #d9d9d9;

  --primary-color: #49A3DA;
  --primary-color-rgb: 73, 163, 218;
  --secondary-color: #024E6B;
  --secondary-color-rgb: 2, 78, 107;
  --tertiary-color: #72C6F3;
  --tertiary-color-rgb: 114, 198, 243;
  --quaternary-color: #97B6C8;
  --quaternary-color-rgb: 151, 182, 200;

  --danger-color: #df6868;
  --success-color: #61e596;
  --warning-color: #ff8247;

  --area-LC-color: #f29393;
  --area-CH-color: #f0a479;
  --area-CN-color: #1abc9c;
  --area-MT-color: #0c8396;

  --padding: 1.6rem;
  --font-size-16px: #{get-rem(16px)};
  --border-radius: 1.429rem;
  --box-shadow: 0 0 10px rgba(0, 0, 0, 0.025);

  --base-color: #777;
  --text-color: #333;

  border-color: var(--bg-color);
}

body {
  background-color: #F3F3F3;
  font-family: 'Evo', sans-serif;
  font-size: 1rem;
}

.container {
  max-width: 1024px;
}
