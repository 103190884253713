.btn {
  box-shadow: none;
}

.btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}

.btn-primary:hover {
  background-color: var(--tertiary-color);
  border-color: var(--tertiary-color);
}

.btn-secondary {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  color: #fff;
}

.btn-secondary:hover {
  background-color: var(--quaternary-color);
  border-color: var(--quaternary-color);
}

.btn-rounded {
  border-radius: 50px;
}
