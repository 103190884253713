.nav-pills {
  > .nav-item {
    > .nav-link {
      height: 32px;
      display: flex;
      align-items: center;;
      line-height: 32px;
      font-weight: 500;
      padding-top: 0;
      padding-bottom: 0;
      color: var(--text-color);
      gap: 0.5rem;

      > svg {
        color: var(--primary-color);
      }

      > span {
        position: relative;
        color: var(--text-color);
      }

      &.active {
        background-color: var(--tertiary-color);
      }

      &:not(.active) {
        border: 1px solid var(--tertiary-color);
      }
    }

    &:first-child > .nav-link {
      border-radius: 0.375rem 0 0 0.375rem;
    }

    &:last-child > .nav-link {
      border-radius: 0 0.375rem 0.375rem 0;
    }
  }
}
