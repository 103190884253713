h1, .h1 {
  font-size: var(--font-size-16px);
  font-weight: 600;
}

h2, .h2 {
  color: var(--primary-color);
  font-size: var(--font-size-16px);
  font-weight: 600;
}

h3, .h3 {
  color: var(--primary-color);
  font-size: var(--font-size-16px);
  font-weight: 500;
}

h4, .h4 {
  color: var(--primary-color);
  font-size: var(--font-size-16px);
  font-weight: 500;
}

h5, .h5 {
  color: var(--primary-color);
  font-size: var(--font-size-16px);
  font-weight: 400;
}

strong {
  font-weight: 500;
}
