::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-color);
}