.card {
  background-color: transparent;
  border: 0;

  > .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--tertiary-color);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    padding: 0.75rem var(--padding);
    color: var(--text-color);
    border: 0;
  }

  > .card-body {
    background-color: #fff;
  }

  > .card-footer {
    min-height: 3rem;
    padding: 0.75rem var(--padding);
    background-color: var(--tertiary-color);
    border-radius: 0 0 var(--border-radius) var(--border-radius);
    border: 0;
  }
}
